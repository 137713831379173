<template>
  <div class="tw-flex tw-w-full tw-h-auto" style="background: white">
    <v-col sm="12" lg="4" class="tw-hidden left-side lg:tw-flex">

      <OnboardingLeft />
    </v-col>
    <v-col sm="12" lg="8" class="right-side scroll" style="position: relative">
      
      <v-col sm="12" class="right-side-body">
        <img src="@/assets/logos/blue.png" alt="logo" style="height: 34px; width: 136px;" class="mb-3 mt-6 img-logo">

        <p class="welcome tw-mt-0">Account Details</p>
        <p class="admin tw-mt-2">
          We would like to have your account details to ease payments, due to the new CBN policy your BVN and NIN is required.
        </p>
        <validation-observer ref="observer" v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(saveData)">
        <v-row class="form">
          <v-col lg="12" sm="12" cols="12" class="tw-w-full tw-flex tw-flex-col tw-items-start">
            <div class="tw-flex tw-py-2">
              <label  class="label">NIN matching Image</label>
            </div>
            <camera @pickedImage="getPickedImage" :current-image="accountDetails.faceImageUrl"/>
          </v-col>
          <v-col lg="6" sm="12" cols="12" class="mb-md-4">
            <ValidationProvider name="Bank Name" rules="required" v-slot="{ classes, errors }">
              <label  class="label">Bank Name</label>
              <div class="tw-pt-1 mb-1 mt-2" :class="classes">
                <v-combobox item-value="bankName" item-text="bankName" placeholder="Sterling Bank" solo hide-details type="text"
                          v-model="accountDetails.bankName" required class="tw-mt-2"
                          :items="banks"  append-icon="mdi-chevron-down"></v-combobox>
                <span>{{ errors[0] }}</span>
              </div>
            </ValidationProvider>
          </v-col>
          
          <v-col lg="6" sm="12" cols="12" class="mb-md-4">
            <ValidationProvider name="Account Type" rules="required" v-slot="{ classes, errors }">
              <label  class="label">Account Type</label>
              <div class="tw-pt-1 mb-1 mt-2" :class="classes">
                <v-combobox  placeholder="Current Account" solo hide-details type="text"
                           v-model="accountDetails.accountType" :items="accountTypes"  required append-icon="mdi-chevron-down"></v-combobox>
                <span>{{ errors[0] }}</span>
              </div>
            </ValidationProvider>

          </v-col>

          <v-col lg="6" sm="12" cols="12" class="mb-md-4">
            <ValidationProvider name="Account Number" rules="required" v-slot="{ classes, errors }">
              <label  class="label">Account Number</label>
              <div class="tw-pt-1 mb-1 mt-2" :class="classes">
                <v-text-field placeholder="0142140595" solo hide-details type="Number"
                              v-model="accountDetails.accountNumber" required class="tw-mt-2"></v-text-field>
                <span>{{ errors[0] }}</span>
              </div>
            </ValidationProvider>
          </v-col>
          
          <v-col lg="6" sm="12" cols="12" class="mb-md-4">
            <ValidationProvider name="Account Name" rules="required" v-slot="{ classes, errors }">
              <label  class="label">Account Name</label>
              <div class="tw-pt-1 mb-1 mt-2" :class="classes">
                <v-text-field placeholder="Aramide Bhadmus" solo hide-details type="text"
                              v-model="accountDetails.accountName" required></v-text-field>
                <span>{{ errors[0] }}</span>
              </div>
            </ValidationProvider>
          </v-col>
          <v-col lg="6" sm="12" cols="12" class="mb-md-4">
            <ValidationProvider name="BVN" rules="required" v-slot="{ classes, errors }">
              <label  class="label">BVN (Bank Verification Number)</label>
              <div class="tw-pt-1 mb-1 mt-2" :class="classes">
                <v-text-field placeholder="02332100121" solo hide-details type="Number"
                              v-model="accountDetails.bvn" required></v-text-field>
                <span>{{ errors[0] }}</span>
              </div>
            </ValidationProvider>
          </v-col>

          <v-col lg="6" sm="12" cols="12" class="mb-md-4">
            <ValidationProvider name="NIN" rules="required" v-slot="{ classes, errors }">
              <label  class="label">NIN (National Identification Number)</label>
              <div class="tw-pt-1 mb-1 mt-2" :class="classes">
                <v-text-field placeholder="12332100000" solo hide-details type="Number"
                              v-model="accountDetails.nin" required></v-text-field>
                <span>{{ errors[0] }}</span>
              </div>
            </ValidationProvider>
          </v-col>

        </v-row>
        <v-divider class="tw-my-5"/>
            <div class="tw-flex tw-w-full tw-flex-col ">
              <h5 class="tw-py-5 deduction-title">Deduction amount for each plan</h5>
              <v-row>
                <v-col lg="6" sm="12" cols="12" class="mb-md-4">
                  <ValidationProvider name="Deduction Type" rules="required" v-slot="{ classes, errors }">
                    <label  class="label">Deduction Type</label>
                    <div class="tw-pt-1 mb-1 mt-2" :class="classes">
                      <v-select placeholder="FIXED" solo hide-details type="text" :items="deductionTypes"
                                    v-model="deductionType" required append-icon="mdi-chevron-down"></v-select>
                      <span>{{ errors[0] }}</span>
                    </div>
                  </ValidationProvider>
                </v-col>

                <v-col lg="6" sm="12" cols="12" class="mb-md-4">
                  <ValidationProvider name="Deduction Amount" rules="required" v-slot="{ classes, errors }">
                    <label  class="label">Deduction Amount</label>
                    <div class="tw-pt-1 mb-1 mt-2" :class="classes">
                      <v-text-field placeholder="500" solo hide-details type="text"
                                    v-model="commissionAmount" required></v-text-field>
                      <span>{{ errors[0] }}</span>
                    </div>
                  </ValidationProvider>
                </v-col>
              </v-row>
            </div>

        <div class="btn-div tw-flex lg:tw-justify-end tw-justify-center">
          <router-link :to="{ name: 'InsuranceProviderOnboardingVerification' }" class="btn-div-text">
            <img src="../../assets/arrow-back.svg" alt="" class="btn-div-img"> 
            <span> 
              Previous  
            </span>
          </router-link>

          <button class="btn-div-btn" type="submit" :disabled="!formIsValid">
            <span v-if="loading">
              <loader-icon class="mb-2"></loader-icon>
              <span>
                Please Wait...
              </span>
            </span>

            <span v-else>
              <span> Done </span>
              <img src="../../assets/arrow_forward.svg" alt="" class="btn-div-img"> 
            </span>

          </button>
        </div>
          </form>
        </validation-observer>
      </v-col>

    </v-col>
    <modal
      :dialog="dialog"
      title="Great, You're all Set "
      additional-text="You’re ready to start managing your transport Company."
      @close="toggleModal"
      :icon="check"
      bottomText="Go To Dashboard"
    />

  </div>
</template>

<script>
  import OnboardingLeft from '@/views/InsuranceProvider/CompanyOnboardingLeft.vue'
  import Modal from '@/components/reuseables/Modal'
  import check from '../../assets/thumbs.svg'
  import {
    getAllBanks,
    getKudaAuthToken,
    onBoardInsuranceProvider,
  } from '@/services/api/APIService'
  import Camera from "../../components/reuseables/Camera.vue";
  import {uploadToCloudinary} from "../../services/api/APIService";

  export default {
    name: "CompanyOnboardingAccountDetails",
    components: {
      Camera,
      OnboardingLeft,
      Modal
    },
    data() {
      return {
        dialog: false,
        loading: false,
        check,
        value : false, 
        confirmValue : false,
        accountDetails:{

        },
        deductionTypes:["FIXED","PERCENTAGE"],
        deductionType:"",
          commissionAmount:"",
        accountTypes:["Corporate","Current","Savings"],
        banks:[]

      }
    },
    computed: {
      storedData() {
        return JSON.parse(sessionStorage.getItem('data'))
      },
      formIsValid() {
        return this.accountDetails.bankName
            && this.accountDetails.accountType
            && this.accountDetails.accountNumber
            && this.accountDetails.accountName
            && this.deductionType !== ""
            && this.deductionAmount !==""
      }
    },
    methods:{
      validAccountNumber(accountNumber) {
        if(accountNumber.length === 10) {
          return true;
        }
        else {
          this.$displaySnackbar({
            message: "Account Number not valid must be 10 digits",
            success: false,
          });
          return false;
        }
      },

      validBvn(accountNumber) {
        if(accountNumber.length === 11) {
          return true;
        }
        else {
          this.$displaySnackbar({
            message: "Account BVN not valid must be 11 digits",
            success: false,
          });
          return false;
        }
      },

  
      clearSession() {
        sessionStorage.removeItem('data')
      },
      toggleModal(){
        this.dialog = !this.dialog
      },

      async saveData() {
        if (this.validAccountNumber(this.accountDetails.accountNumber) ){
          let userData = JSON.parse(sessionStorage.getItem('userData'))
          let data = {
            ...this.storedData,
            companyEmail: userData.email,
            userId : userData.id,
            accountDetail:this.accountDetails,
            deductionType: this.deductionType,
              commissionAmount: this.commissionAmount
          }
          data.accountDetail.userEmail = userData.email
          this.banks.forEach(bank => {
            if (bank.bankName === data.accountDetail.bankName.bankName){
              data.accountDetail.bankName = data.accountDetail.bankName.bankName
              data.accountDetail.bankCode = bank.bankCode
            }
          })

          try {
            this.loading = true
            if (this.accountDetails.faceImageUrl){
              let newData = {
                file: this.accountDetails.faceImageUrl,
                upload_preset: process.env.VUE_APP_CLOUDINARY_UPLOAD_PRESET
              }
              const response = await uploadToCloudinary(newData)
              this.accountDetails.faceImageUrl = response.data.url
            }
            const response = await onBoardInsuranceProvider(data)
            this.loading = false
            this.$displaySnackbar({ message: response.data.details, success: true })
            this.dialog = true
            this.clearSession()
            let userData = JSON.parse(sessionStorage.getItem("userData"))
            sessionStorage.setItem("userData",JSON.stringify(userData))
            await this.$router.push({name: 'AwaitingVerification'})
          } catch (err) {
            console.log(err.response)
            this.$displaySnackbar({
              message: err.response.data.details[0],
              success: false
            })
            this.loading = false
          }
        }
      },
      async getPickedImage(pickedImage){
        if (pickedImage){
          this.accountDetails.faceImageUrl = pickedImage
        }
      }
    ,
      async getAllNgBanks(){
        let kudaLoginData = {}
        kudaLoginData.email = process.env.VUE_APP_KUDAEMAIL
        kudaLoginData.apiKey= process.env.VUE_APP_KUDAAPIKEY
        await getKudaAuthToken(kudaLoginData).then(async res => {
          sessionStorage.setItem("kudaJwt", res.data)
          let data = {}
          data.data= "{\"serviceType\": \"BANK_LIST\",\"requestRef\": \"123456789\"}"
          await getAllBanks(data).then(res => {
            const objectJson = JSON.parse(res.data.data)
            objectJson.Data.banks.forEach(bank => this.banks.push(bank))

          }).catch(() => {})
        })

      }
    },
    async created() {
      await this.getAllNgBanks()
        if (!this.banks.length){
            window.location.reload()
        }
    }
  }
</script>

<style lang="scss" scoped>
button:disabled,
button[disabled]{
  opacity: 0.7;
}
  .scroll {
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 99vh;
    height: 98vh;
  }

  .scroll::-webkit-scrollbar {
    display: none;
  }


.left-side{
  width: 100%;
  min-height: 100vh;
  background-image: url('../../assets/onboarding-bg.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  padding: 0!important;
  color: #ffffff;

  @media screen and (max-width: 1024px){
    display: none;
  }
}




.right-side-body{
  width: 100%;
  padding:7rem;
  @media screen and (min-width: 1440px) {
    padding-top: 4.25rem;
  }
  @media screen and (max-width: 1440px ){
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
  @media screen and (max-width: 1366px ) and (min-width: 1025px){
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  @media screen and (max-width: 1024px ){
    padding: 1.5rem;
  }
}
.right-side-body > .img-logo{
  @media screen and (min-width: 1024px){
    display: none;
  }
}


.right-side-body .welcome {
  font-family: "Inter", serif;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: left;
  color: #004AAD;
  @media screen and (max-width: 1024px){
    margin-top: 50px;
  }
}

.right-side-body .admin {
  width: 555px;
  height: 24px;
  font-family: "Inter", serif!important;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.04em;
  text-align: left;
  color: #828282;

  @media screen and (max-width: 768px) {
    width: 100%;
  }
}


.label {
  font-family: "Inter",serif !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0;
  text-align: left;
  color: #4f4f4f;
}

.deduction-title {
  font-family: "Inter",serif !important;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0;
  text-align: left;
  color: #4f4f4f;
}

.btn-div{
  margin-top: 52px;
  @media screen and (max-width: 1023px){
    margin-top: 25px;
  }
}

.btn-div .btn-div-text:first-child {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 30px;
  width: 161px;
  border-radius: 10px;
  background: white;
  color: #004AAD;
  border: 1px solid #004AAD;
  .btn-div-img {
    margin-right: 5px;
  }
}

.btn-div .btn-div-btn {
  margin-left: 25px;
  // padding: 10px 30px;
  width: 161px;
  
  border-radius: 10px;
  background: #004AAD;
  color: white;
  span {
    display: flex; 
    align-items: center; 
    justify-content: center;

  }
  .btn-div-img {
    margin-left: 5px;
  }
}

.form {
  margin-top: 46px;
}

.input-upload-box{
  display: flex;
  align-items: flex-end;
  margin-top: 5px;
  .img {
    margin-right: 10px;
    width: 72px;
    height: 72px;
    border: 1px solid #BDBDBD;
    position: relative;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    .imgg {
      width: auto;
      height: 100%;
      border-radius: 10px;
    }
  }
  .input-upload-box-btn {
    color: #004AAD;
  }
}

.form {
  margin-top: 37px;
  @media screen and (max-width: 1024px) {
    margin-top: 60px;
  }
}

</style>